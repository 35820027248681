<template>
    <div class="row download files-wrapper pb-3 mb-3 mt-2">
        <b-col v-for="(attachment,index) in inputVal"
               :key="index"
               :cols="4"
               class="image-preview"
        >
            <div class="background">
                <font-awesome-icon class="file-type"
                                   v-if="fileIcons[attachment.name.split('.').pop()]"
                                   size="4x"
                                   :icon="fileIcons[attachment.name.split('.').pop()]"
                ></font-awesome-icon>
                <font-awesome-icon class="file-type"
                                   v-else
                                   size="4x"
                                   icon="file"
                ></font-awesome-icon>

                <a target="_blank"
                   :href="mediaUrl(attachment.id,attachment.type)"
                   v-if="['attachment','offering_attachment','article','article_attachment','position'].includes(attachment.type)"
                   :data-type="attachment.type"
                >{{ attachment.name.replace(/^.*[\\\/]/, '') }}</a>
                <a v-else-if="attachment.type=='contract'"
                   @click.prevent="$root.openDocument(attachment.name, 'customer_contracts')"
                   :href="$root.getDocumentUrl(attachment.name, 'customer_contracts')"
                   :data-type="attachment.type"
                >{{ attachment.name }}</a>
                <a v-else
                   @click.prevent="$root.openDocument(attachment.name, attachment.type=='simple' ? 'simple':'invoices')"
                   :href="$root.getDocumentUrl(attachment.name, attachment.type=='simple' ? 'simple':'invoices')"
                   :data-type="attachment.type"
                >{{ attachment.name }}</a>

                <div class="remove" v-if="!attachment.is_base">
                    <b-button variant="danger" @click="removeFile(attachment)"
                              :title="$t('common.title.delete')">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col :cols="4">
            <div id="customdropzone">
                <div>
                    <b-dropdown class="ml-2" left>
                        <template #button-content>
                            <font-awesome-icon icon="upload" size="2x"></font-awesome-icon>
                            {{ $t('common.label.append_file') }}
                        </template>

                        <b-dropdown-item
                            @click="$root.$children[0].openModal('attachments-modal',{
                                offering_id:offeringId,
                                customer_id:customerId,
                                modalType:modalType,
                                'type':'server'},addFile)">
                            {{ $t('common.media.label.from_server') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="$root.$children[0].openModal('attachments-modal',{
                                offering_id:offeringId,
                                customer_id:customerId,
                                parent_id:parent_id,
                                modalType:modalType,
                                'type':'local'},addFile)">
                            {{ $t('common.media.label.from_computer') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </b-col>
    </div>
</template>

<script>
export default {
    name: 'zw-attachments',
    props: {
        value: [Array],
        offeringId: [String, Number],
        customerId: [String, Number],
        parent_id: [String, Number],
        sales_attachment: [String],
        modalType: {
            type: String,
            default: 'email',
        },
    },
    data() {
        return {
            fileIcons: {
                'pdf': 'file-pdf',
                'docx': 'file-word',
                'doc': 'file-word',
                'csv': 'file-csv',
                'png': 'file-image',
                'jpg': 'file-image',
                'gif': 'file-image',
                'jpeg': 'file-image',
                'webp': 'file-image',
                'txt': 'file-lines',
            }
        }
    },
    methods: {
        mediaUrl(id, type) {
            const routeData = this.$router.resolve({
                name: 'Open',
                params: {type: 'media', name: id, media_type: type}
            });
            return routeData.href
        },
        addFile(file) {
            this.inputVal.push(file)
        },
        removeFile(file) {
            this.inputVal = this.inputVal.filter(attachment => {
                return attachment.id != file.id
            })
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>
<style lang="scss">

</style>