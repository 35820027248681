<template>
    <b-row>
        <b-col cols="6">
            <zw-input-group v-model="inputVal.expire"
                            name="expire_after_hours"
                            type="number"
                            :label-prefix="labelPrefix"
            ></zw-input-group>
        </b-col>

        <b-col cols="12">
            <b-form-checkbox v-model="inputVal.stop_after_first_click">{{ $t('settings.workflow.label.stop_after_first_click') }}</b-form-checkbox>
        </b-col>
    </b-row>
</template>

<script>

import {mapGetters} from "vuex";
import ZwAttachments from "@/components/ZwAttachments";

export default {
    name: 'SendEmailOptions',
    components: {ZwAttachments},
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {},
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>